import React, { useState, useEffect } from 'react';
import { SiGithub, SiLinkedin } from '@icons-pack/react-simple-icons';
import { Mail } from 'lucide-react';
import annoyingPianoImg from './images/annoying-piano.png';
import vocabFinderImg from './images/vocab-finder.png';

const JimmyPortfolio = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [audioMode, setAudioMode] = useState(false);
  const [sparkles, setSparkles] = useState([]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const addSparkle = (x, y) => {
    const newSparkle = {
      id: Date.now(),
      x,
      y,
      size: Math.random() * 10 + 5
    };
    setSparkles(prev => [...prev, newSparkle]);
    setTimeout(() => {
      setSparkles(prev => prev.filter(s => s.id !== newSparkle.id));
    }, 1000);
  };

  const projects = [
    {
      title: "🎹 The Annoying Piano",
      description: "An interactive musical experience pushing creative boundaries using Next.js, TypeScript, and Tone.js",
      tags: ["Next.js", "TypeScript", "Tone.js", "Creativity"],
      image: annoyingPianoImg, 
      demoLink: "https://annoying-piano.vercel.app",
      repoLink: "https://github.com/jimmyNicholas/next-annoying-piano"
    },
    {
      title: "📚 Vocab Finder",
      description: "Interactive word exploration tool bridging education and technology",
      tags: ["Node.js", "JavaScript", "UI/UX", "Education"],
      image: vocabFinderImg,
      demoLink: "https://vocabfinder.jimmynicholas.com/",
      repoLink: "https://github.com/jimmyNicholas/Vocab_Finder_v2"
    },
    // {
    //   title: "📱 QR Code Learning",
    //   description: "Streamlined student performance tracking system",
    //   tags: ["JavaScript", "Google API", "Education Tech"],
    //   image: "/api/placeholder/600/400", // Replace with your actual image path
    //   demoLink: "https://qr-learning.vercel.app",
    //   repoLink: "https://github.com/yourusername/qr-learning"
    // }
  ];

  const skills = [
    { name: "Front-End Development", icon: "💻" },
    { name: "Creative Problem Solving", icon: "🎯" },
    { name: "Educational Technology", icon: "📚" },
    { name: "Music Production", icon: "🎵" },
    { name: "Cross-Cultural Communication", icon: "🌏" },
    { name: "User Experience Design", icon: "✨" }
  ];

  return (
    <div className="bg-black text-white overflow-hidden relative">
      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
          .sparkle {
            position: absolute;
            pointer-events: none;
            animation: sparkle-fade 1s forwards;
          }
          @keyframes sparkle-fade {
            0% { transform: scale(0) rotate(0deg); opacity: 0; }
            50% { transform: scale(1) rotate(180deg); opacity: 1; }
            100% { transform: scale(0) rotate(360deg); opacity: 0; }
          }
          .piano-key {
            transition: all 0.3s ease;
          }
          .piano-key:hover {
            transform: translateY(-5px);
            background: linear-gradient(45deg, #6366f1, #8b5cf6);
          }
        `}
      </style>

      {/* Interactive background */}
      <div 
        className="fixed w-64 h-64 pointer-events-none blur-3xl opacity-20 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full transition-all duration-200 ease-out"
        style={{
          transform: `translate(${mousePosition.x - 128}px, ${mousePosition.y - 128}px)`,
        }}
      />

      {/* Sparkles */}
      {sparkles.map(sparkle => (
        <div
          key={sparkle.id}
          className="sparkle fixed w-2 h-2 bg-white rounded-full"
          style={{
            left: sparkle.x - 1,
            top: sparkle.y - 1,
          }}
        />
      ))}

      {/* Hero Section */}
      <header className="min-h-screen flex items-center relative">
        <div className="max-w-6xl mx-auto px-4 py-32 relative z-10">
          <div className="mb-8 overflow-hidden">
            <h1 
              className="text-8xl font-bold mb-4 transform hover:scale-105 transition-transform duration-500 cursor-pointer"
              onClick={() => {
                addSparkle(mousePosition.x, mousePosition.y);
                setAudioMode(!audioMode);
              }}
            >
              Jimmy
              <br />
              Nicholas
            </h1>
          </div>
          <p className="text-2xl text-gray-400 max-w-2xl ml-1 hover:text-white transition-colors duration-300">
            Bridging Music, Education, and Code
          </p>
          
          {/* Piano Keys Interface */}
          <div className="flex gap-1 mt-8">
            {[...Array(8)].map((_, i) => (
              <div
                key={i}
                className="piano-key w-12 h-32 bg-gray-800 rounded-b-lg cursor-pointer"
                onMouseEnter={() => addSparkle(mousePosition.x, mousePosition.y)}
              />
            ))}
          </div>
        </div>
      </header>

      {/* Projects Section */}
      <section className="py-16 md:py-24 relative">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold mb-16 hover:tracking-wider transition-all duration-300">
            Creative Works
          </h2>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {projects.map((project, index) => (
              <div
                key={index}
                className="group relative bg-gray-900 rounded-lg transform hover:scale-105 transition-all duration-500 hover:bg-gray-800 overflow-hidden"
                onMouseEnter={() => addSparkle(mousePosition.x, mousePosition.y)}
              >
                {/* Project Image */}
                <div className="relative w-full h-48 overflow-hidden">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  {/* Overlay gradient */}
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900/90" />
                </div>

                {/* Content */}
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-3">{project.title}</h3>
                  <p className="text-gray-400 mb-4 group-hover:text-white transition-colors duration-300">
                    {project.description}
                  </p>
                  
                  {/* Tags */}
                  <div className="flex flex-wrap gap-2 mb-4">
                    {project.tags.map((tag, tagIndex) => (
                      <span
                        key={tagIndex}
                        className="px-3 py-1 bg-gray-800 text-gray-300 text-sm rounded-full group-hover:bg-gray-700 transition-colors duration-300"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>

                  {/* Links */}
                  <div className="flex gap-4 mt-4">
                    <a
                      href={project.demoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-sm text-white/80 hover:text-white transition-colors duration-300 bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded-lg"
                    >
                      <span>Live Demo</span>
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                    </a>
                    <a
                      href={project.repoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-sm text-white/80 hover:text-white transition-colors duration-300 bg-gray-800 hover:bg-gray-700 px-4 py-2 rounded-lg"
                    >
                      <span>Code</span>
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                      </svg>
                    </a>
                  </div>
                </div>

                {/* Hover gradient overlay */}
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg pointer-events-none" />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section className="py-16 md:py-24 relative">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold mb-16 hover:tracking-wider transition-all duration-300">
            Skillset
          </h2>
          <div className="flex flex-wrap gap-6">
            {skills.map((skill, index) => (
              <div
                key={index}
                className="group relative px-6 py-4 bg-gray-900 rounded-lg transform hover:scale-110 transition-all duration-300 hover:bg-gray-800 cursor-pointer"
                style={{
                  animation: `float ${3 + index * 0.5}s ease-in-out infinite`
                }}
                onMouseEnter={() => addSparkle(mousePosition.x, mousePosition.y)}
              >
                <span className="text-2xl mb-2 block">{skill.icon}</span>
                <span className="text-lg block">{skill.name}</span>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 md:py-24 relative">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold mb-16 hover:tracking-wider transition-all duration-300">
            Connect
          </h2>
          <div className="flex gap-8">
            {[
              { Icon: SiGithub, href: "https://github.com/jimmyNicholas", label: "Github" },
              { Icon: SiLinkedin, href: "https://www.linkedin.com/in/jimmy-nicholas/", label: "LinkedIn" },
              { Icon: Mail, href: "mailto:jimmynicholas@duck.com", label: "Email" }
            ].map(({ Icon, href, label }) => (
              <a
                key={label}
                href={href}
                className="group relative p-4 bg-gray-900 rounded-lg transform hover:scale-110 transition-all duration-300 hover:bg-gray-800"
                onMouseEnter={() => addSparkle(mousePosition.x, mousePosition.y)}
              >
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg" />
                <Icon className="w-8 h-8 group-hover:text-white transition-colors duration-300" />
              </a>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default JimmyPortfolio;
